/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import {XmlEntities} from 'html-entities';
import { useStaticQuery, graphql } from "gatsby"

const entities = new XmlEntities();
const htmlDecode = input => entities.decode(input);

function SEO({ description, lang, meta, title, data, pageTitle, seoTitle }) {
  const { site, logoBlack } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        logoBlack: file(relativePath: {eq: "logo-black.svg"}){
          publicURL
        }
      }
    `
  )

  // const seoTitle = pageTitle ? data.title : `${title} | Codicate`;

  const metaDescription = data && data.metadesc ? data.metadesc : site.siteMetadata.description;

  const ogImage = data && data.opengraphImage ? data.opengraphImage.sourceURL : (site.siteMetadata.siteUrl + logoBlack.publicURL);

  const twitterImage = data && data.twitterImage ? data.twitterImage.sourceURL : ogImage;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={htmlDecode(title)}
      titleTemplate={!seoTitle ? `%s | ${site.siteMetadata.title}` : `${htmlDecode(seoTitle)}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: seoTitle ? seoTitle : title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: ogImage
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:image`,
          content: twitterImage
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
