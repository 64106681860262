import React from 'react';
import TransitionLink from "gatsby-plugin-transition-link"


const TransitionButton = (props) => {
    const {children, to = '/', className,pageBackground,tabIndex} = props;

    return (
        <TransitionLink to={to} aria-label={props['aria-label']} className={`${className ? className : ''}`}
                        exit={{
                            length: 1.2,
                            state: {
                                background: pageBackground
                            },
                        }}
                        tabIndex={tabIndex}
                        activeClassName="active"
                        partiallyActive={true}
                        entry={{delay: 1.2,length: 1.2}}>
            {children}
        </TransitionLink>


    )
}

export default TransitionButton;