/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useRef, useEffect, useState} from "react"
import PropTypes from "prop-types"
// import {StaticQuery, graphql} from "gatsby"
import {useSiteMetadata} from "./data-hooks/use-site-metadata";
import './../resources/styles/main.scss'
import SEO from './seo';
import Header from "./header/index";
import Footer from "./footer/index";
import withLocation from "./withLocation"
import MovingSquares from "./moving-squares/index";
import GridSquares from "./grid-squares/index";
import { TransitionState} from "gatsby-plugin-transition-link"
// import {useInView} from "react-intersection-observer";
import Utils from "../utils/utils";
import MessgaeFromDecor from './message-from-decor/index'
import 'animation.gsap'
import 'debug.addIndicators'
import ScrollMagic from 'scrollmagic';

const TweenMax = require('TweenMax');


const Layout = ({
                    search,
                    children,
                    className = 'home',
                    isIncludeFooter,
                    footerOffset,
                    mainContainerHeight,
                    hasGrisSquares = true,
                    hasMovingSquares = true,
                    yoastData,
                    pageTitle,
                    seoTitle = null
                }) => {

    useSiteMetadata();

    let footerRef = useRef(null);
    // const footerHeight = (footerRef.current && footerRef.current.clientHeight) || 0;
    const [menuOnFooter, setMenuOnFooter] = useState(false);
    const scene = [];
    const {fromDecor} = search; //query url to know if we been redirected from decor-fromDecor.com
    const size = Utils.useWindowSize();
    useEffect(() => {
        //componentDidMount

        const controller = new ScrollMagic.Controller();
        if (footerOffset) {


            const offsetMoveContainer = footerOffset - size.height;
            //duration is set to the end of the footer (a place we will never reach)
            // const durationMoveContainer = document.querySelector('footer').offsetHeight +1;
            // hack to fix the fact that the next contrainer move up animation render all animation in first time it occours
            scene[0] = new ScrollMagic.Scene({offset: 0})
                .setTween('.container', {y: '0%', ease: TweenMax.Linear.easeNone})
                .addTo(controller);

            // build scene - last section up - footer enter
            scene[1] = new ScrollMagic.Scene({offset: offsetMoveContainer})
                .setClassToggle('.container', 'sliding')
                // .addIndicators({name: '1 - container up'}) // add indicators
                .addTo(controller);
            // build scene - last section up - footer enter
            scene[2] = new ScrollMagic.Scene({triggerElement: "footer"})
                .on("enter", () => setMenuOnFooter(true))
                .on("leave", () => setMenuOnFooter(false))
                // .addIndicators({name: 'menu on footer'}) // add indicators
                .addTo(controller);

            document.querySelector('.main-container').style.height = footerOffset ? (footerOffset + 'px') : 'auto' ;
        }

        else if(mainContainerHeight){
            document.querySelector('.main-container').style.height = mainContainerHeight ? (mainContainerHeight + 'px') : 'auto' ;
        }

        return () => {
            //componentDidUnMount

            scene.forEach(function (el) {
                el.destroy(true);
                el = null;
            });
        }
    }, []);

    return (
                <TransitionState>
                    {({transitionStatus, exit}) => {
                        //adding class to change bg with animation if needed
                        const toBgClass = exit.state !== undefined && transitionStatus !== 'entered' ? exit.state.background : '';
                        const dynamicClass = className + ' ' + transitionStatus + ' ' + toBgClass + (menuOnFooter ? 'dark' : '') + ' ' + (fromDecor ? 'fromDecor' : '');

                        return (
                            <div className={`main-wrapper ${dynamicClass}`}>
                                {/*<TransitionPortal>*/}
                                <SEO data={yoastData} title={pageTitle} seoTitle={seoTitle}/>
                                <MessgaeFromDecor startsOpen={fromDecor === '1'}/>
                                <Header className={`${dynamicClass}`}/>
                                {/*</TransitionPortal>*/}
                                <div className="main-container" style={{
                                    position: "relative",
                                }}>
                                    <main className={`container`}>
                                        <div className={`${"grid"}`}>
                                            {hasMovingSquares && <MovingSquares/>}
                                            {children}
                                            {hasGrisSquares && <GridSquares/>}
                                        </div>
                                    </main>
                                </div>
                                {isIncludeFooter &&
                                <div className="footerContainer"
                                     ref={footerRef}
                                ><Footer/></div>
                                }
                            </div>
                        )
                    }}
                </TransitionState>

    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    footerOffset: PropTypes.number,
    mainContainerHeight: PropTypes.number,
    hasGrisSquares: PropTypes.bool,
    hasMovingSquares: PropTypes.bool,
}

Layout.defultProps = {
    hasGrisSquares: true,
    hasMovingSquares: true,
}

export default withLocation(Layout)
