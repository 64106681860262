import React, {Component} from 'react';
import './style.scss';
import './mobile.scss';
import PropTypes from 'prop-types';
import {IS_MOBILE} from "../../utils/constants";

class GridSquares extends Component {

        constructor(props) {
            super(props)


            const {rowsAmount} = this.props;

            var squaresArray = [];
            for(var i = 0;i<rowsAmount*24;i++){
                const colorId = Math.floor(Math.random()*3);
                const groupId = Math.floor(Math.random()*20);
                squaresArray.push({className : 'color-' + colorId + ' group-' + groupId});
            }

            this.state = {
                squaresArray: squaresArray
            }
        }

        render() {
            const {className, id, rowsAmount} = this.props;
            return (
                <div
                    className={`${className}`}
                    id={`${id}`}
                    style={{
                        gridTemplateRows: `repeat(${rowsAmount}, var(--cube-length))`
                    }}
                >
                {
                    this.state.squaresArray.map((item,index) => (
                        <b className={item.className} key={index} />
                    ))
                }
                </div>
            )
        }
    }

GridSquares.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    rowsAmount: PropTypes.number,
};

GridSquares.defaultProps = {
    className: 'grid-squares',
    id: 'grid-squares',
    //TODO: we need to set Is_MOBILE after component did mount
    rowsAmount: IS_MOBILE ? 15 :11,
};

export default GridSquares