import React, {useEffect, useState} from "react"
import './style.scss';
import './mobile.scss';

import CodicateImage from "../codicate-image/index";
import yearsIcon from '../../resources/images/footer/years-icon.png'
import clientsIcon from '../../resources/images/footer/clients-icon.png'
import usersIcon from '../../resources/images/footer/users-icon.png'

import fireglass from '../../resources/images/footer/companies-icons/fireglass.svg'
import helpi from '../../resources/images/footer/companies-icons/helpi.svg'
import jolt from '../../resources/images/footer/companies-icons/jolt.svg'
import wjc from '../../resources/images/footer/companies-icons/wjc.svg'
import wxg from '../../resources/images/footer/companies-icons/wxg.svg'
import secdo from '../../resources/images/footer/companies-icons/secdo.svg'
import cato from '../../resources/images/footer/companies-icons/cato.svg'
import comverse from '../../resources/images/footer/companies-icons/comverse.svg'
import davidson from '../../resources/images/footer/companies-icons/davidson-icon.png'
import magic from '../../resources/images/footer/companies-icons/magic.svg'
import medinol from '../../resources/images/footer/companies-icons/medinol.svg'
import verint from '../../resources/images/footer/companies-icons/verint.svg'
import iClassical from '../../resources/images/footer/companies-icons/iclassical.svg'

import GridSquares from "../grid-squares/index";
import Carousel from 'nuka-carousel';
import {IS_MOBILE} from "../../utils/constants";
import ContactForm from "../contact-form/index";
import {graphql, useStaticQuery} from "gatsby";

const sliderSettings = {
    initialSlideWidth: 300,
    withoutControls: true,
    autoplay: true,
    autoplayInterval:5000,
    wrapAround: true,
    heightMode: 'max',
};

const sliderSettingsPartnersIcons = {
    withoutControls: true,
    wrapAround: true,
    slidesToShow: 1.5,
    cellAlign: "center",
    slideIndex: 1,
    width: 'auto',
};

const startUpsIcons = [
    {
        extraWide: false,
        className: 'cato',
        image: cato,
    }, {
        extraWide: false,
        className: 'secdo',
        image: secdo,
    }, {
        extraWide: false,
        className: 'helpi',
        image: helpi,
    }, {
        extraWide: false,
        className: 'fireglass',
        image: fireglass,
    }, {
        extraWide: false,
        className: 'iClassical',
        image: iClassical,
    }, {
        extraWide: false,
        className: 'jolt',
        image: jolt,
    },
];

const enterprisesIcons = [
    {
        extraWide: false,
        className: 'magic',
        image: magic,
    }, {
        extraWide: false,
        className: 'wxg',
        image: wxg,
    }, {
        extraWide: false,
        className: 'verint',
        image: verint,
    }, {
        extraWide: false,
        className: 'comverse',
        image: comverse,
    }, {
        extraWide: true,
        className: 'davidson',
        image: davidson,
    }, {
        extraWide: false,
        className: 'wjc',
        image: wjc,
    }, {
        extraWide: false,
        className: 'medinol',
        image: medinol,
    },
];

const mobileOrderEnterprisesIcons = [
    'wjc',
    'verint',
    'wxg',
    'comverse',
    'magic',
    'medinol',
    'davidson',
];

const mobileOrderStartUpsIcons = [
    'cato',
    'secdo',
    'helpi',
    'iClassical',
    'jolt',
    'fireglass',
];

const sortIcons = (logos, orderArray) => {
    return logos.concat()
        .sort((a,b) => {
        return orderArray.findIndex((item) => item === a.className) - orderArray.findIndex((item) => item === b.className)
    })
}


const printIconsMobile = (iconsMobile) => {

    //always even number of icons and infinite show handled
    const icons = [...iconsMobile, ...iconsMobile].sort((a, b) => b.extraWide - a.extraWide);
    //always even number of icons and infinite show handled
    const evenIcons = [];
        icons.forEach((item, index) => {
        if (index % 2 === 0) {
            evenIcons.push(
                <div
                    className="carousel-slide"
                    key={`-${index}-${item.className}`}
                >
                    {[
                        icons[index],
                        !icons[index].extraWide ? icons[index + 1] : null
                    ].map((iconItem, iconItemIndex) =>
                        iconItem &&
                        <CodicateImage
                            key={`${iconItem.className}-${iconItemIndex}`}
                            alt="icon"
                            img={iconItem.image}
                            className={iconItem.className}
                        />)
                    }
                </div>
            )
        }
    })
    return evenIcons;
}


const Footer = () => {
    const data = useStaticQuery(graphql`
    query {
        noa: file(relativePath: { eq: "footer/noa.png"}) {
            childImageSharp {
                fixed(width: 189){
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        amit: file(relativePath: { eq: "footer/amit.png"}) {
            childImageSharp {
                fixed(width: 189){
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        lior: file(relativePath: { eq: "footer/lior.png"}) {
            childImageSharp {
                fixed(width: 189){
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        desktopRocket: file(relativePath: { eq: "footer/contact-red-image.png"}) {
            childImageSharp {
                fluid(maxHeight: 405, quality: 100){
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        mobileRocket: file(relativePath: { eq: "footer/rocketMobile.png" }) {
            childImageSharp {
                fluid(maxWidth: 386, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        thankYouRocket: file(relativePath: { eq: "footer/thank-you.png"}) {
            childImageSharp {
                fluid(maxHeight: 404, quality: 100){
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
       thankYouRocketMobile: file(relativePath: { eq: "footer/rocketMobile copy.png" }) {
            childImageSharp {
                fluid(maxWidth: 371, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if(IS_MOBILE) {
            setIsMobile(true)
        }

    }, []);


    const [submitSuccess, setSubmitSuccess] = useState(false);

    const handleSubmitSuccess = () => setSubmitSuccess(true);

    const formSources = [
        data.desktopRocket.childImageSharp.fluid,
        {
            ...data.mobileRocket.childImageSharp.fluid,
            media: `(max-width: 544px)`,
        }
    ];

    const submitSuccessSources = [
        data.thankYouRocket.childImageSharp.fluid,
        {
            ...data.thankYouRocketMobile.childImageSharp.fluid,
            media: `(max-width: 544px)`,
        }

    ];

    const rocketSources = submitSuccess ? submitSuccessSources : formSources;

    const path = (typeof window !== 'undefined' && window.location.pathname) || "/";

    return (
        <footer className="footer">
            <section className="experience-clients-users">
                <ul>
                    <li>
                        <CodicateImage alt="stars icon" img={yearsIcon}/>
                        <span className="number">15</span>
                        <span className="description">YEARS OF EXPERIENCE</span>
                    </li>
                    <li>
                        <CodicateImage alt="hands shaking" img={clientsIcon}/>
                        <span className="number">200+</span>
                        <span className="description">CLIENTS</span>
                    </li>
                    <li>
                        <CodicateImage alt="users icon" img={usersIcon}/>
                        <span className="number">100K+</span>
                        <span className="description">ACTIVE USERS</span>
                    </li>
                </ul>
            </section>
            <section className="partners whiteBg">
                <GridSquares rowsAmount={isMobile ? 20 : 10} id="grid-squares-footer-partners" className="grid-squares grid-squares-footer"/>
                <h1>OUR PARTNERS</h1>
                <span className="subTitle">Building long term relationships since 2005</span>
                <div className="clients-sections">
                    <div className="startups">
                        <span className="section-title">STARTUPS</span>
                        <div className="icons-container desktop">
                            {startUpsIcons.map(({image, className}, index) =>
                                <CodicateImage
                                    alt="icon" img={image} className={className}
                                    key={`--${index}-${className}`}
                                />
                            )}
                        </div>
                        <div className="only-mobile icons-container-carousel">
                            <Carousel {...sliderSettingsPartnersIcons}>
                                {
                                    printIconsMobile(sortIcons(startUpsIcons, mobileOrderStartUpsIcons))

                                }
                            </Carousel>
                        </div>
                    </div>
                    <div className="separator"/>

                    <div className="enterprises">
                        <span className="section-title">ENTERPRISES</span>
                        <div className="icons-container desktop">
                            {enterprisesIcons.map(({image, className}, index) =>
                                <CodicateImage
                                    alt="icon" img={image} className={className}
                                    key={`--${index}-${className}`}
                                />
                            )}
                        </div>
                        <div className="only-mobile icons-container-carousel">
                            <Carousel {...sliderSettingsPartnersIcons}>
                                {
                                    printIconsMobile(sortIcons(enterprisesIcons, mobileOrderEnterprisesIcons))
                                }
                            </Carousel>
                        </div>
                    </div>
                </div>
                <div className="partners-carousel-container partners-quotations">
                    <Carousel {...sliderSettings}>
                        <div className="partner-quotation-container">
                            <div className="image-section">
                                <CodicateImage alt="icon" img={data.lior}/>
                            </div>
                            <div className="text-section">
                                <span className="quotation-marks">“</span>
                                <p className="description">Codicate is developing our mobile app since its first version. They are highly professionals, responsive and functioning as our own in-house dev team. Their doer approach and their problem-solving attitude were key success in our project. I highly recommend them.</p>
                                <div className="partner-name-position">
                                    <span className="name">Lior Frenkel</span>
                                    <span className="position-company">CPO @ Jolt</span>
                                </div>
                                <span className="time-collaboration">Collaborate since 2017</span>
                            </div>
                        </div>
                        <div className="partner-quotation-container">
                            <div className="image-section">
                                <CodicateImage alt="icon" img={data.noa}/>
                            </div>
                            <div className="text-section">
                                <span className="quotation-marks">“</span>
                                <p className="description">When it comes to outsourcing development, finding the right people can be one of the hardest tasks. Alon & Codicate's team adopted Helpi like it was their own startup. They are real professionals, the communication with them is great, and as long as they are working on our project - we know we are in good hands.</p>
                                <div className="partner-name-position">
                                    <span className="name">Noa Urbach</span>
                                    <span className="position-company">Co-Founder @ Helpi</span>
                                </div>
                                <span className="time-collaboration">Collaborate since 2016</span>
                            </div>
                        </div>
                        <div className="partner-quotation-container">
                            <div className="image-section">
                                <CodicateImage alt="icon" img={data.amit}/>
                            </div>
                            <div className="text-section">
                                <span className="quotation-marks">“</span>
                                <p className="description">During my work with Codicate, I met a highly skilled team that cares for details. Codicate learned our project requirements deeply, made research and proposed creative solutions for all challenges. Codicate’s success providing a high quality-functional product under a tight schedule and varying requirements is admirable.</p>
                                <div className="partner-name-position">
                                    <span className="name">Amit Braytenbaum</span>
                                    <span className="position-company">Former VP of Product @ Verint</span>
                                </div>
                                <span className="time-collaboration">Collaborate since 2012</span>
                            </div>
                        </div>
                    </Carousel>
                </div>
            </section>
            <section className="contact blueBg">
                <GridSquares rowsAmount={isMobile ? 21 : 6} id="grid-squares-footer-contact" className="grid-squares grid-squares-footer"/>

                <div className={`box-image-container ${submitSuccess ? 'submit-success' : ''}`}>
                    <div className="image-wrapper">
                        <CodicateImage alt="icon" className={isMobile ? "only-mobile contact-rocket" : "desktop"} fluid={rocketSources}/>
                    </div>
                    <div className="contact-box">
                        <h1>GET IN TOUCH</h1>
                        <ContactForm
                            path={path}
                            SubmitButton={() => <button>SEND</button>}
                            onSubmitSuccess={handleSubmitSuccess}
                            leftAlignButton={true}
                            formName="contactFooter"
                        />
                    </div>
                </div>
            </section>
        </footer>
    )
}

export default Footer


