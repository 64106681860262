import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import TransitionButton from "../transition-button"
import './style.scss';
import './mobile.scss';
import {IS_MOBILE, services_paths} from '../../utils/constants';

function Header({className}) {
    const servicesMenu = useRef();
    const menu = useRef();
    const [path, setPath] = useState('/');
    const [isCurrentPathService, setIsCurrentPathService] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [showServices, setShowServices] = useState(false);
    const [serviceHover, setServiceHover] = useState(null);
    const [isMobile, setIsMobile] = useState(IS_MOBILE);

    const handleClickOutsideServicesMenu = e => {
        if (servicesMenu.current.contains(e.target) || e.target.id === 'ServiceBtn') {
            // inside click
            setTimeout(() => {
                setShowServices(false)
            }, 200)
            return;
        }
        // outside click
        setShowServices(false);

    };
    const handleClickOutsideMenuOnMobile = e => {
        if (e.target.id === 'ServiceBtn') {
            return;
        }
        if (menu.current.contains(e.target)) {
            // inside click
            setTimeout(() => {
                setShowMenu(false)
            }, 200)
            return;
        }
        // outside click
        setShowMenu(false);
    };

    useEffect(() => {

        if (IS_MOBILE) {
            setIsMobile(true)
        }


        if (typeof window === 'undefined') return;

        const {pathname} = window.location;

        setPath(pathname);

        return () => {
        };
    }, []);

    useEffect(() => {
        setIsCurrentPathService(!!services_paths.find((item) => item === path));
        return () => {
        };
    }, [path]);

    useEffect(() => {
        if (showServices) {
            document.addEventListener("mousedown", handleClickOutsideServicesMenu);
        } else {
            document.removeEventListener("mousedown", handleClickOutsideServicesMenu);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideServicesMenu);
        };
    }, [showServices]);


    useEffect(() => {
        if (showMenu && IS_MOBILE) {
            document.addEventListener("mousedown", handleClickOutsideMenuOnMobile);
        } else {
            document.removeEventListener("mousedown", handleClickOutsideMenuOnMobile);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutsideMenuOnMobile);
        };
    }, [showMenu]);


    const servicesItemsData = [
        {
            to: "/mobile-native-applications",
            pageBackground: "toBlueBg",
            className: "mobile-btn",
            label: "Mobile Native Applications"
        },
        {
            to: "/custom-made-systems",
            pageBackground: "toRedBg",
            className: "custom-btn",
            label: "Custom Made Systems"
        },
        {
            to: "/website-development",
            pageBackground: "toYellowBg",
            className: "website-btn",
            label: "Website Development"
        }
    ];

    const servicesItems = []

    for (const [index, item] of servicesItemsData.entries()) {
        servicesItems.push(
            <li key={index} onMouseLeave={() => setServiceHover()} onMouseEnter={() => {
                if (!isMobile) setServiceHover(index + 1)
            }}><TransitionButton
                tabIndex={showServices? 0 :-1} to={item.to} pageBackground={item.pageBackground}
                className={item.className}>{item.label}</TransitionButton></li>
        )
    }

    const enterPressed = e => {
        const code = e.keyCode || e.which;
        if (code === 13) { //13 is the enter keycode
            showServicesToggle();
        }
    };
    const showServicesToggle = () => {
        if (!showServices) {
            setShowServices(true)
        }
    }

    return (
        <header
            className={`${className} ${(showMenu || showServices) ? 'open' : ''}`}
        >
            <div className="logo">
                <TransitionButton aria-label="go to homepage"
                    pageBackground="toBlueBg"
                    to='/'
                className="a11y-hidden">Codicate Logo</TransitionButton>
            </div>
            <div className="menu">
                <button aria-label="Toggle menu (open and close)" onClick={() => setShowMenu(!showMenu)} className="only-mobile toggleMenu" id="toggleMenuBtn">
                    <b></b><b></b>
                </button>
                <ul ref={menu}>
                    <li className={`with-menu ${showServices ? 'open' : ''} ${isCurrentPathService ? 'active' : ''}`}>
                        <span tabIndex="0" id="ServiceBtn"
                              onKeyPress={enterPressed.bind(this)}
                              onClick={showServicesToggle}
                        >Services</span>
                        <button onClick={() => setShowServices(false)} className="closeServices" aria-label="Close services menu"><b></b><b></b>
                        </button>
                        <ul ref={servicesMenu} className={`${serviceHover ? 'bg-' + serviceHover : ''}`}>
                            {servicesItems}
                        </ul>
                    </li>
                    <li><TransitionButton to="/about" pageBackground="toWhiteBg">About</TransitionButton></li>
                    <li><TransitionButton to="/projects" pageBackground="toBlueBg">Projects</TransitionButton></li>
                    <li><TransitionButton to="/careers" pageBackground="toBlueBg">Careers</TransitionButton></li>
                    <li><TransitionButton to="/contact" pageBackground="toWhiteBg">Contact Us</TransitionButton></li>
                </ul>
            </div>
        </header>

    );
}

Header.propTypes = {
    showMenu: PropTypes.bool,
    showServices: PropTypes.bool,
    serviceHover: PropTypes.number,
};

export default Header