import {totalDurationFactor} from "./constants";
import {useState, useEffect} from 'react';


export default class Utils {
    // Hook
    static useWindowSize() {
        if (typeof window === 'undefined') return;
        const isClient = typeof window === 'object';

        function getSize() {
            return {
                width: isClient ? window.innerWidth : undefined,
                height: isClient ? window.innerHeight : undefined
            };
        }

        const [windowSize, setWindowSize] = useState(getSize);

        useEffect(() => {
            if (!isClient) {
                return false;
            }

            function handleResize() {
                setWindowSize(getSize());
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []); // Empty array ensures that effect is only run on mount and unmount

        return windowSize;
    }

    static getCubeLengthInPixels() {
        if (typeof window === 'undefined') return;

        return ((9.09 / 100) * window.innerHeight);
    }

    static calcDuration(duration) {
        if (typeof window === 'undefined') return;
        return duration * totalDurationFactor * window.innerHeight;
    }

    static calcDurationByRegularScreen(duration) {
        if (typeof window === 'undefined') return;
        return duration * window.innerHeight;
    }

    static calcSectionOffset(sectionsTiming, sectionIndex) {

        const lastIndex = sectionIndex !== undefined ? sectionIndex : sectionsTiming.length;
        return sectionsTiming.slice(0, lastIndex).reduce(function (acc, curr) {
            return acc + curr.duration;
        }, 0);
    }

    static calcFooterOffset = (sectionsTiming) => this.calcSectionOffset(sectionsTiming);

    static numFormetter(number){
        if(number && number < 10){
            return `0${number}`
        }
        else{
            return `${number}`
        }
    }

    /**
     * Check if component is in viewport.
     * @param el
     * @param offset
     * @return {boolean}
     */
    static isInViewport = (el, offset = 0) => {
        if (typeof window === 'undefined') return false;
        const curr = el.hasOwnProperty('current') ? el.current : el;

        const top = curr.getBoundingClientRect().top;

        return (top + offset) >= 0 && (top - offset) <= window.innerHeight;
    };

    static gTagEvent = ({category, action, label, value='', nonInteraction=false}) => {
        return (typeof window === 'undefined' || window.gtag === undefined) ? null :
            window.gtag('event', action, {event_category: category, event_label: label, value, non_interaction: nonInteraction})
    };


}