export const IS_MOBILE = typeof window !== 'undefined' && document.documentElement.clientWidth < 768;

export const IS_TABLET = typeof window !== 'undefined' && document.documentElement.clientWidth >= 768 && document.documentElement.clientWidth <= 1024;

export const IS_DESKTOP = typeof window !== 'undefined' && document.documentElement.clientWidth > 1024;

export const totalDurationFactor = 2;

export const SCROLL_DIRECTIONS = {
    PAUSED: "PAUSED",
    FORWARD: "FORWARD",
    REVERSE: "REVERSE",
};

export const CUBE_LENGTH = '9.09vh';

export const TECH_ICON_MAPPER = {
    react: '/project-inner/react-icon.png',
    python: '/project-inner/python-icon.png',
    angular: '/project-inner/angular.png',
    drupal: '/project-inner/drupal.png',
    gatsby: '/project-inner/gatsby.png',
    nodeJs: '/project-inner/nodejs.png',
    reactNative: '/project-inner/react-native.png',
    vueJs: '/project-inner/vuejs.png',
    wordpress: '/project-inner/wordpress.png',
}

export const PLATFORM_ICON_MAPPER = {
    desktop: '/project-inner/desktop.svg',
    tablet: '/project-inner/ipad.svg',
    mobile: '/project-inner/iphone.svg',
}

export const services_paths =
    [
        "/custom-made-systems",
        "/mobile-native-applications",
        "/website-development",

    ];
