import React, {useState, useEffect} from "react";
import useForm from "react-hook-form";
import CodicateImage from "../codicate-image";
import facebookBlue from '../../resources/images/footer/facebook-icon.png'
import facebookWhite from '../../resources/images/contact/fb.svg';
import linkedinWhite from '../../resources/images/contact/linkedin.svg';
import linkedinBlue from '../../resources/images/footer/linkdin-icon.png'
import Utils from '../../utils/utils';
import './style.scss';
import './mobile.scss';
import {IS_MOBILE} from "../../utils/constants";
import TransitionButton from '../transition-button';

const validateEmail = email => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email) || "Invalid email";
};


const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
};

const ThankYou = ({name}) => {
    const displayName = name.toUpperCase();
    return (
        <div className="thank-you">
            <h3>THANK YOU</h3>
            <h2>{displayName}</h2>
            <p>We will contact you shortly</p>
                <span>Meanwhile you can see more of</span>
                <TransitionButton
                    className="btn"
                    to="/projects"
                >OUR PROJECTS</TransitionButton>
        </div>
    )
}

const ContactForm = ({SubmitButton, path, showContactInfo = true, onSubmitSuccess, leftAlignButton, whiteIcons = false, formName}) => {
    const [isMobile, setIsMobile] = useState(false);

    const {register, handleSubmit, errors} = useForm();

    const [submitSuccess, setSubmitSuccess] = useState(false);

    const [userName, setUserName] = useState('');
    // const textAreaMessage = useRef();

    const onSubmit = (async (data, e) => {
        if (typeof window === 'undefined') return;
        e.preventDefault();

        fetch(path, {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: encode({
                "form-name": formName,
                ...data
            })
        }).then(response => {
            setUserName(data.full_name || "");
            setSubmitSuccess(true);

            if(onSubmitSuccess) {

                onSubmitSuccess(true);
            }

            Utils.gTagEvent({
                category: 'Contact Form',
                action: 'Submit Form',
                label: path
            })
            setShowMessage('Thank you! We\'ll be in touch!');
        }).catch(err => setShowMessage('There was a problem submitting the form'));

    });

    const showMessageArea = () => {
        setShowMessage(true);
    }

    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if(IS_MOBILE) {
            setIsMobile(true)
        }
        if (typeof document === 'undefined' || !IS_MOBILE) return;

        const messageElement = document.querySelector('#message-element');
        if(messageElement && showMessage){
            messageElement.focus()
        }
        return () => {
        }

    }, [showMessage])


    const facebookIcon = whiteIcons ? facebookWhite : facebookBlue;
    const linkdinIcon = whiteIcons ? linkedinWhite : linkedinBlue;


    return (
        <div className={`form-container ${showContactInfo ? 'with-info' : ''} ${submitSuccess ? 'success' : ''}`}>
            {
                !submitSuccess ?
                    <form action={path} method="POST" name={formName} data-netlify={true} onSubmit={handleSubmit(onSubmit)}>
                        <div className={`form-content ${showContactInfo ? 'with-info' : ''}`}>
                            <input type="hidden" name="form-name" value="contact"/>
                            <label htmlFor={'full_name'}>FULL NAME</label>
                            <input
                                placeholder="Enter your name"
                                className={errors.full_name ? 'invalid' : ''} name={'full_name'} id={'full_name'} type={'text'}
                                ref={register({required: true, maxLength: 20})}/>
                            <label htmlFor={'email'}>EMAIL</label>
                            <input
                                placeholder="Enter your email"
                                className={errors.email ? 'invalid' : ''} name={'email'} id={'email'} type={'text'} ref={register({required: true, maxLength: 20, validate: validateEmail})}/>
                            <label htmlFor={'company'}>COMPANY</label>
                                < input
                                placeholder = "Enter your company name"
                                className={errors.company ? 'invalid' : ''} name={'company'} id={'company'} type={'text'} ref={register({required: true, maxLength: 40})}/>
                            {
                                !isMobile || showMessage ?
                                    <>
                                    <label htmlFor={'message-element'}>MESSAGE</label>
                                    <textarea id="message-element"
                                              className={errors.message ? 'invalid' : ''} name={'message'} placeholder={'What can we build together?'} ref={register({required: true, maxLength: 500})}/>
                                    </>
                                    :
                                    <>
                                    <div onClick={showMessageArea} role="button" tabIndex={0}>
                                        <span className="add-message">ADD A MESSAGE</span>
                                    </div>
                                    </>
                            }
                            {!leftAlignButton && <SubmitButton/>}
                        </div>
                        {
                            showContactInfo
                                ?
                                <div className="info-send-container">
                                    <span className="just-say">Or just say</span>
                                    <a className="mail-link" href="mailto:hello@codicate.com">hello@codicate.com</a>
                                    <a className="phone-link" href="tel:077-40-80-270">077-40-80-270</a>
                                    <ul className="social-links">
                                        <li>
                                            <a href="https://www.linkedin.com/company/codicate/" target="_blank" rel="noopener noreferrer">
                                                <CodicateImage alt="icon" img={linkdinIcon}/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/codicate/" target="_blank" rel="noopener noreferrer">
                                                <CodicateImage alt="icon" img={facebookIcon}/>
                                            </a>
                                        </li>
                                    </ul>
                                    {leftAlignButton && <div className="send-container">
                                        <SubmitButton/>
                                    </div>
                                    }
                                </div>
                                : null
                        }

                    </form>
                    :
                    <ThankYou
                        name={userName}
                    />

            }
        </div>
    )
};

export default ContactForm;