import React, {useState} from 'react';
import Img from 'gatsby-image';


const CodicateImage = (props) => {
    const {img, id, blur, fixed, fluid} = props;
    const [loaded, setLoaded] = useState(false);

    if(!img && !fixed && !fluid) return <div></div>;

    const classes = [
        "codicate-image",
        props.className,
        (blur ? (loaded ? "loaded" : 'loading') : null),
    ].filter(x => x);

    if (img && ((img.publicURL && !img.childImageSharp) || typeof img === 'string')) {
        return (
            <img
                id={id}
                src={img.publicURL || img}
                className={classes.join(' ')}
                alt={props.alt}
                style={props.style}
            />
        );
    } else if((props.fixed && props.fixed.length) || (props.fluid && props.fluid.length)) {

        return (
            <Img
                fluid={props.fluid || null}
                fixed={props.fixed || null}
                fadeIn={!!blur}
                onLoad={() => blur ? setLoaded(true) : () => {}}
                {...props}
                className={classes.join(' ')}
            />
        )
    } else {
        const {childImageSharp} = props.img;

        return (
            <Img
                fluid={childImageSharp.fluid ? childImageSharp.fluid : null}
                fixed={childImageSharp.fixed ? childImageSharp.fixed : null}
                sizes={childImageSharp.sizes ? childImageSharp.sizes : null}
                style={{...props.style}}
                fadeIn={!!blur}
                onLoad={() => blur ? setLoaded(true) : () => {}}
                {...props}
                className={classes.join(' ')}
            />
        )
    }
};

export default CodicateImage;