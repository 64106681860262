import React,{useState} from 'react';
import './style.scss';
import CodicateImage from "../codicate-image/index";
import decorLogo from '../../resources/images/decor-logo.svg'
import arrow from '../../resources/images/arrow.svg'
import codicateLogo from '../../resources/images/logo-white.svg'
import Lightbox from '../lightbox/index'

const MessgaeFromDecor = ({startsOpen}) => {
    const [isLongMessage, setIsLongMessage] = useState(false);

    return (
        <Lightbox startsOpen={startsOpen} className={isLongMessage ? 'showLong' : ''}>
            <div className='redirectMessage'>
                <CodicateImage img={decorLogo} className={decorLogo} alt="decor logo"/>
                <CodicateImage img={arrow} alt="arrow img"/>
                <CodicateImage img={codicateLogo} alt="codicate logo" />
                Decor is now Codicate, <button onClick={() => setIsLongMessage(true)} className="readMoreLink">read more</button>
            </div>
            <div className='longMessage'>
                <strong>Decor is now Codicate</strong>
                <p>We founded Decor in 2005, two brothers that learned to code by their father and fell in love with the developing internet world.</p>

                <p>Over the years we met you, our loyal customers and business partners, together we shared successes and challenges.
                Today, We’re excited to unveil our refreshed brand identity which reflects our evaluation over the years:</p>

                <CodicateImage img={codicateLogo} alt="codicate logo" />


                <p>We are Codicate, a development studio based on dedication, uncompromising quality, innovation, and constant improvement.</p>

                <p>In the spirit of rebranding, we’d like to take the opportunity to thank you for your trust and friendship in business.</p>

                <p>Alon & Oren Roth</p>
            </div>
        </Lightbox>
    )
};

export default MessgaeFromDecor