import React, {Component} from 'react';
import Parallax from 'parallax-js'
import './style.scss';

class MovingSquares extends Component {
        componentDidMount() {
            this.parallax = new Parallax(this.scene)
        }
        componentWillUnmount() {
            this.parallax.disable()
        }

        render() {
            return (
                <ul ref={el => this.scene = el} id="movingSquares" data-relative-input="true">
                    <li className="red-square" data-depth="0.10"></li>
                    <li className="yellow-square" data-depth="0.80"></li>
                    <li className="yellow-square" data-depth="0.70"></li>
                    <li className="yellow-square" data-depth="0.60"></li>
                    <li className="light-blue-square" data-depth="0.724"></li>
                    <li className="red-square" data-depth="0.19"></li>
                    <li className="yellow-square" data-depth="0.18"></li>
                    <li className="red-square" data-depth="0.164"></li>
                    <li className="blue-square" data-depth="0.20"></li>
                    <li className="yellow-square" data-depth="0.574"></li>
                </ul>
        )
        }
    }

export default MovingSquares