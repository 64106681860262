import React, {useState,useEffect} from 'react';
import './style.scss';
import './mobile.scss';

const Lightbox = ({children,startsOpen,className}) => {
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        if(startsOpen) {
            setIsOpen(true)
        }

    }, []);
    return (
        <div className={'lightboxWrapper' + (isOpen ? ' open' : '') + ' ' + className}>
            <div className="innerWrapper">
                <button aria-label="close floating window" className='closeBtn' onClick={() => setIsOpen(false)} ><b></b><b></b>
                </button>
                {children}
            </div>
        </div>
    )
};

export default Lightbox